.edit_button, .edit_button:hover, .edit_button:focus, .edit_button:active {
	float: right;
	background-color: unset !important;
	border: none;
	width: 9px;
	font-size: 11px;
	line-height: 14px;
	height: 23px;
	padding-right: 20px;
	box-shadow: none;
}

.edit_button svg {
	color: #7F7F7F;
}

.edit_button svg:hover {
	color: black;
}
