.Toastify__toast--default {
	background: #fff !important;
	color: #aaa !important;
}

.Toastify__toast--info {
	background: #3498db !important;
}

.Toastify__toast--success {
	background: #27873b !important;
}

.Toastify__toast--warning {
	background: #f1c40f !important;
}

.Toastify__toast--error {
	background: #e74c3c !important;
}

.Toastify__toast-body {
	padding-left:15px;
}