body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.edit_button, .edit_button:hover, .edit_button:focus, .edit_button:active {
	float: right;
	background-color: unset !important;
	border: none;
	width: 9px;
	font-size: 11px;
	line-height: 14px;
	height: 23px;
	padding-right: 20px;
	box-shadow: none;
}

.edit_button svg {
	color: #7F7F7F;
}

.edit_button svg:hover {
	color: black;
}

.Toastify__toast--default {
	background: #fff !important;
	color: #aaa !important;
}

.Toastify__toast--info {
	background: #3498db !important;
}

.Toastify__toast--success {
	background: #27873b !important;
}

.Toastify__toast--warning {
	background: #f1c40f !important;
}

.Toastify__toast--error {
	background: #e74c3c !important;
}

.Toastify__toast-body {
	padding-left:15px;
}
